import {createContext, useState, useEffect, useCallback, ReactNode, useContext, useMemo} from 'react';
import {
    useGoogleLogin,
} from "@react-oauth/google";

import {
    httpGet,
    httpGetExternal,
    httpPost,
} from '../helpers/http.helper';
import {useAuthContext} from "./AuthContext";

type GoogleUser = {
    id: string;
    email: string;
    verified_email: boolean;
    name: string;
    given_name: string;
    family_name: string;
    picture: string;
    locale: string;
};

type GoogleAuthContextType = {
    googleUser: GoogleUser | null | undefined;
    isInitialConnect: boolean | undefined;
    login: () => void;
};

const GoogleAuthContext = createContext<GoogleAuthContextType>({} as GoogleAuthContextType);

export const useGoogleAuthContext = (): GoogleAuthContextType => useContext(GoogleAuthContext);

export const GoogleAuthProvider = ({ children }: {children: ReactNode}) => {
    const { loggedIn, username } = useAuthContext();
    const [googleUser, setGoogleUser] = useState<GoogleUser | null | undefined>(undefined);
    const [isInitialConnect, setIsInitialConnect] = useState<boolean | undefined>(undefined);

    const gmailAuth = useCallback(async () => {
        let currentToken = await httpGet(
            `googleAuth/getCurrentUserToken/${username}`
        );
        let googleResponse: any;

        let currentTokenJson = await currentToken.json();
        if (currentToken) {
            if (currentTokenJson.currentToken === null) {
                setIsInitialConnect(true);
            } else {
                setIsInitialConnect(false);
            }
            googleResponse = await httpGetExternal(
                `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${currentTokenJson.currentToken}`,
                {
                    Authorization: `Bearer ${currentTokenJson.currentToken}`,
                    Accept: "application/json",
                }
            );

            if (!googleResponse.ok) {
                setGoogleUser(null);
            } else {
                const googleResponseJson = await googleResponse.json();
                setGoogleUser(googleResponseJson);
            }
        }
    }, [username]);

    useEffect(() => {
        if (loggedIn && !googleUser) {
            gmailAuth();
        }
        if (!loggedIn) {
            setGoogleUser(undefined);
            setIsInitialConnect(undefined);
        }
    }, [googleUser, gmailAuth, loggedIn]);

    const login = useGoogleLogin({
        flow: "auth-code",
        redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
        scope:
            "https://www.googleapis.com/auth/tasks https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.labels https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
        onSuccess: async (codeResponse: any) => {
            let GenTokenRequest = {
                code: codeResponse.code,
                userEmail: username,
            };
            try {
                let response = await httpPost(
                    "googleAuth/GenerateTokens",
                    GenTokenRequest
                );
                let gmailDetailsJson = await response.json();
                setGoogleUser(gmailDetailsJson);
            } catch (error) {
                console.error("OAuth2 Error: " + error);
            }
        },
        onError: (error) => console.log("Login Failed:", error),
    });

    const value = useMemo(() => ({
        googleUser,
        isInitialConnect,
        login,
    }), [googleUser, login, isInitialConnect]);

    return (
        <GoogleAuthContext.Provider
            value={value}
        >
            {children}
        </GoogleAuthContext.Provider>
    );
};
