import * as React from 'react';
import '../index.css';
import { useEffect, useState } from 'react';
import { Button, Form, Input, Popconfirm,  Divider } from 'antd';
import { httpPut, httpGet } from '../helpers/http.helper';
import { NumberOutlined, EditOutlined } from '@ant-design/icons';
import styles from '../style/custom.module.css'
import 'bootstrap/dist/css/bootstrap.css';
import {useAuthContext} from "../contexts/AuthContext";
import {useTranslation} from "react-i18next";

interface Categories {
    categoryName: string;
    categoryDescription: string;
}

const CustomCategory = ({ setUserCategoryChange = {} as any, isCatagoryChanged = {} as any, isSubmit = {} as any }) => {
    // @ts-ignore TODO shouldn't have to do this, fix.
    const {t} = useTranslation();
    const { username } = useAuthContext();
    const [form] = Form.useForm();
    const [rows, setRows] = useState<Categories[]>([]);
    const [isError, setIsError] = useState(false);


    useEffect(() => {
        loadData();
    }, [setRows]);

    const loadData = async () => {
        const result = await loadCategorie();
        setIsError(false);
        setRows(result);
    }

    const loadCategorie = async () => {
        const data: any = [];
        try {
            const response = await httpGet(`getCustomCategorys/${username}`);
            if (response.ok) {

                let userCategorie = await response.json();
                if (userCategorie) {
                    for (const item of userCategorie) {
                        data.push({ categoryName: item.categoryName, categoryDescription: item.categoryDescription });
                    }
                    return data;
                }
            } else {
                throw new Error(t('profile.preferences.errors.categoriesNotFound'));
            }
        } catch (error) {
            console.log(`Exception --> ${error}`);
        }
        return [];
    };


    const handleSubmit = async () => {

        setUserCategoryChange(rows);
        isCatagoryChanged(true);
    };


    const updateCategoryMapping = async (categoryMapping: any) => {
        const data = {
            category: categoryMapping,
            userEmail: username
        }

        const response = await httpPut('updateCustomCategorys', data);


        if (response.status === 200) {
            return { status: 'OK' };
        } else {
            throw new Error(t('profile.preferences.errors.categoriesUpdateFailed'));
        }
    };




    const handleInputChange = (e: any, index: any) => {

        const values = [...rows];
        if (e.target.name === 'categoryName') {
            values[index].categoryName = e.target.value;
        } else if (e.target.name === 'categoryDescription') {
            values[index].categoryDescription = e.target.value;
        }
        setRows(values);
        setUserCategoryChange(values);
        isCatagoryChanged(true);
    };




    const handleAddRow = () => {
        setRows([
            ...rows,
            {
                categoryName: '',
                categoryDescription: '',
            },

        ]);
    };

    const handleRemove = async (e: any, index: any) => {

        const values = [...rows];

        const updatedArray = values.filter((_, i) => i !== index);
        setRows(updatedArray);
        setUserCategoryChange(updatedArray);
        isCatagoryChanged(true);
         await updateCategoryMapping(updatedArray);

    };

    return (
        <div className={styles.userProfilePreferencesCategories}>
            {rows.map((row, index,) => (
                <>
                    <Form form={form} onFinish={handleSubmit}>
                        <label>{t('profile.preferences.categoryNameLabel')}</label>

                        <Input maxLength={15} value={row.categoryName} className={isError ? 'error' : ''}
                            required type="text" name="categoryName" placeholder={t('profile.preferences.categoryNamePlaceholder')}
                            prefix={<NumberOutlined className="site-form-item-icon" />}
                            onChange={(e) => handleInputChange(e, index)} />

                        <label>{t('profile.preferences.categoryDescriptionLabel')}</label>

                        <Input maxLength={500} value={row.categoryDescription} className={isError ? 'error' : ''}
                            required type="text" name="categoryDescription" placeholder={t('profile.preferences.categoryDescriptionPlaceholder')}
                            prefix={<EditOutlined className="site-form-item-icon" />}
                            onChange={(e) => handleInputChange(e, index)} />

                        <Popconfirm title={t('profile.preferences.confirmDeleteCategory')} cancelText={t('common.cancel')} okText={t('common.confirm')} okType="danger" onConfirm={(e) => handleRemove(e, index)}>
                            <Button className={["btn btn-danger btn-sm"].join(' ')}>{t('common.remove')}</Button>
                        </Popconfirm>
                    </Form>
                    <Divider />
                </>
            ))}
            <Form.Item>
                <Button className={["btn btn-primary btn-sm"].join(' ')} onClick={handleAddRow}>{t('profile.preferences.addCategoryButton')}</Button>
                <Button hidden={true} onClick={handleSubmit}>{t('common.submit')}</Button>
            </Form.Item>

        </div>
    );

}
export default CustomCategory;
