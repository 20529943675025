import { Link } from 'react-router-dom';
import styles from '../../style/custom.module.css'
import {useAuthContext} from "../../contexts/AuthContext";
import {useTranslation, Trans} from "react-i18next";

const NotFound404 = () => {
  // @ts-ignore TODO shouldn't have to do this, fix.
  const {t} = useTranslation();
  const { loggedIn} = useAuthContext();
  return (
    <div className={styles.NotFound404}>
      <svg width="50%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <title>404 - Not Found</title>
        <path d="m15.5,15c-1.378,0-2.5,1.122-2.5,2.5v3c0,1.378,1.122,2.5,2.5,2.5s2.5-1.122,2.5-2.5v-3c0-1.378-1.122-2.5-2.5-2.5Zm1.5,5.5c0,.827-.673,1.5-1.5,1.5s-1.5-.673-1.5-1.5v-3c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v3ZM21.5,1H2.5C1.122,1,0,2.122,0,3.5v19.5h6v-1H1v-13h22v4h1V3.5c0-1.378-1.122-2.5-2.5-2.5ZM1,8V3.5c0-.827.673-1.5,1.5-1.5h19c.827,0,1.5.673,1.5,1.5v4.5H1Zm4-3c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Zm3,0c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Zm3,0c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Zm12,10h1v8h-1v-2h-2.5c-.827,0-1.5-.673-1.5-1.5v-4.5h1v4.5c0,.276.224.5.5.5h2.5v-5Zm-12,0h1v8h-1v-2h-2.5c-.827,0-1.5-.673-1.5-1.5v-4.5h1v4.5c0,.276.224.5.5.5h2.5v-5Z" />
      </svg>
      <p>
        <Trans
          i18nKey={loggedIn ? 'notFound.goToProfileCTA' : 'notFound.goToLoginCTA'}
          components={{
            anchor: <Link to={'/'} />
          }}
          shouldUnescape={true}
        />
      </p>
    </div>
  );
};

export default NotFound404;