import React from 'react';
import ReactDOM from 'react-dom/client';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';
import { GoogleOAuthProvider } from "@react-oauth/google";

import './index.css';
import './translations';
import reportWebVitals from './reportWebVitals';

import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (!process.env.REACT_APP_GMAIL_CLIENTID) {
  throw new Error('REACT_APP_GMAIL_CLIENTID is not defined');
}

// TODO - get CRA working with __webpack_nonce__ and use a nonce value for the prod build instead of unsafe-inline
const csp = `
  default-src 'self'; 
  connect-src 'self' www.googleapis.com ${process.env.REACT_APP_API_HOST} api.stripe.com;
  script-src 'self' js.stripe.com accounts.google.com; 
  frame-src 'self' support.newmail.ai js.stripe.com; 
  child-src 'self' support.newmail.ai js.stripe.com; 
  style-src 'self' 'unsafe-inline';
`;

const Index = () => {
  // @ts-ignore TODO shouldn't have to do this, fix.
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta name="description" content={t('meta.description')} />
        <meta
          http-equiv="Content-Security-Policy"
          content={csp}/>
      </Helmet>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GMAIL_CLIENTID as string}>
        <App />
      </GoogleOAuthProvider>
    </>
  );
}

root.render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
