import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {useAuthContext} from "../contexts/AuthContext";
import {useTranslation} from "react-i18next";

const ProtectedRoute = () => {
  const { t } = useTranslation();
  const {loggedIn} = useAuthContext();

  if (loggedIn === null) {
    return <div>{t('common.loading')}</div>;
  }

  return loggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;