import Cookies from "js-cookie";

let token: null | string = Cookies.get('nm') || null;

// onForbidden should be assigned via setOnForbidden
let onForbidden: () => any = () => console.error('401 handler not bound in http helper');

export const setAuthToken = (newToken: string) => {
    token = newToken;
}
export const setOnForbidden = (newOnForbidden: typeof onForbidden) => {
    onForbidden = newOnForbidden;
}

export const clearAuthToken = () => {
    token = null;
}

const header = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "X-Frame-Options": "DENY",

};

export const httpPost = async (url: string, body: any) => {
    const post = await fetch(`${process.env.REACT_APP_EXTERNAL_API_URL}${url}`, {
        method: "POST",
        headers: {
            ...header,
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body),
    });
    if (post.status === 401) {
        onForbidden();
    }
    return post;
}

export const httpPut = async (url: string, body: any) => {
    const post = await fetch(`${process.env.REACT_APP_EXTERNAL_API_URL}${url}`, {
        method: "PUT",
        headers: {
            ...header,
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body),
    });
    if (post.status === 401) {
        onForbidden();
    }
    return post.json();
}

export const httpGet = async (url: string, session: any = null) => {
    try {
        const get = await fetch(`${process.env.REACT_APP_EXTERNAL_API_URL}${url}`, {
            method: "GET",
            headers: {
                ...header,
                'Authorization': `Bearer ${token}`
            },
        });
        if (get.status === 401) {
            onForbidden();
        }
        return get;
    } catch (error) {
        console.error(`HTTP GET request failed: ${error}`);
        throw error;
    }
}

export const httpGetExternal = async (url: string, header = {}) : Promise<any> =>{
    const get = await fetch(`${url}`, {
        method: "GET",
        headers: {
            ...header,
        },
    })
    return get;
}
