import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import styles from '../../style/custom.module.css'
import { httpPost, httpGet } from '../../helpers/http.helper';

import { useOnboardingContext, ONBOARD_STAGES } from "../../contexts/OnboardingContext";
import { useAuthContext } from "../../contexts/AuthContext";

import OnboardingStepper from "../onboardingStepper";
import Membership from '../membership';
import UserPreferences from '../userPreferences';
import { useTranslation } from "react-i18next";

const UserProfile = () => {
  // @ts-ignore TODO shouldn't have to do this, fix.
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const [stripeSession, setStripeSession] = useState<any>("");
  const [stripePlanId, setstripePlanId] = useState<any>([]);
  const [initiateTrial, setInitiateTrial] = useState(false);

  const {
    stage,
    setHasCompletedCheckoutBefore,
    setHasActivePaidSubscription
  } = useOnboardingContext();

  const { username, userId } = useAuthContext();

  useEffect(() => {
    validatePaymentStatus();
    console.log(`stripe session : ${stripePlanId}`);
  }, []);


  const validatePaymentStatus = async () => {
    let sessionId: any = "";
    try {
      if (searchParams.get('success') === null) {
        await getUserSubscription();
      }
      else if (searchParams.get('success') === 'fail') {
        setStripeSession("")
        setstripePlanId([]);
      }
      else {
        sessionId = searchParams.get('success');
        await getstripeSessionDetails(sessionId ?? "");
        await getUserSubscription();
      }
    } catch (error) {
      if (!sessionId)
        sendStripeSubscriptionFailureNotification(sessionId);
    }
  }


  const getstripeSessionDetails = async (sessionId: string) => {
    try {
      if (stripePlanId.id !== undefined) {
        return;
      }
      console.log(` getstripeSessionDetails --> sesson id : ${stripeSession}`);

      let stripeSessionResult = await httpGet(`getStripeSessionDetails/${sessionId}/${userId}`);
      let stripeSessionDetails: any = await stripeSessionResult.json();

      if (stripeSessionDetails.status === 'success') {
        const session = stripeSessionDetails.data
        if (session.subscription === null) {
          let res = await httpGet(`getSubscriptionPrice/${username}`);
          let proceList: any = await res.json();
          updateSubscription(sessionId, null, 'success', proceList)
          return;
        }
        console.log(`getstripeSessionDetails : Session -->${JSON.stringify(session)}`)
        await getstripeSuscriptionDetails(sessionId, session.subscription)
      }
      else {
        sendStripeSubscriptionFailureNotification(sessionId);
      }

    }
    catch (error) {
      alert(t('profile.membership.errors.invalidStripeSession'));
      console.log(`Exception : Session -->${error}`)
    }
  }
  const sendStripeSubscriptionFailureNotification = async (sessionId: string) => {
    debugger;
    let session: any;
    try {
      let stripeSessionResult = await httpGet(`getStripeSessionDetails/${sessionId}/${userId}`);
      let stripeSessionDetails: any = await stripeSessionResult.json();

      if (stripeSessionDetails.status === 'success') {
        session = stripeSessionDetails.data;
      }
    }
    catch (error) {
      console.log(`Exception : Session -->${error}`)
    }

    const subscriptionJson = {
      "email": username,
      "issueDate": new Date(),
      "userId": userId,
      "type": "subscription",
      "sessionId": sessionId,
      "subscriptionId": ""
    };

    if (session) {
      subscriptionJson.type = session.subscription === null ? "trial" : "subscription";
      subscriptionJson.subscriptionId = session.subscription === null ? "" : session.subscription;
    }
    await httpPost("subscriptionFailureNotification", subscriptionJson);
  };

  const getstripeSuscriptionDetails = async (sessionId: string, subscriptionsId: string) => {
    try {
      let subscription: any;
      if (subscriptionsId === "") return;

      let stripeSubscriptionResult = await httpGet(`getStripeSubscriptionDetails/${subscriptionsId}/${userId}`);
      let subscriptionDetails: any = await stripeSubscriptionResult.json();

      if (subscriptionDetails.status === 'success') {
        subscription = subscriptionDetails.data;
      }

      setStripeSession(sessionId)
      let res = await httpGet(`getSubscriptionPrice/${username}`);
      let proceList: any = await res.json();
      await updateSubscription(sessionId, subscription, "success", proceList);

    }
    catch (error) {
      console.log(`Exception : Session -->${error}`)
    }
  }

  const updateSubscription = async (sessionId: string, subscription: any, status: any, proceList: any) => {
    try {
      let subscriptionJson = {};
      let isAutorenewal = false;
      let isTrial: boolean = false;


      if (subscription === null || subscription === undefined || subscription.plan === undefined) {
        isTrial = true;
      }
      else {
        const index = proceList.findIndex((obj: { lookupKey: string }) => obj.lookupKey === subscription.plan.id);
        const currentPlan = proceList[index];
        if (currentPlan.type.toLowerCase() === "trial") isTrial = true;
      }

      if (!isTrial) {
        if (subscription.items.data[0].price.type === "recurring") {
          //Auto-renewal is enabled
          isAutorenewal = true;
        }
      }

      let subscriptionExpiryOn: any = null;
      let date = new Date();
      if (status === "success" && subscription != null) {
        const planType = subscription.items.data[0].plan.interval;
        switch (planType) {
          case "month":
            date.setDate(date.getDate() + 30);
            break;
          case "year":
            date.setDate(date.getDate() + 365);
            break;
          case "free":
            break;
        }
        subscriptionExpiryOn = formatDate(date.toDateString());
      }

      let amount = ((!isTrial ? parseFloat(subscription.items.data[0].plan.amount) : 0) / 100).toFixed(2);
      subscriptionJson = {
        "email": username,
        "price": amount,
        "status": status === "success" ? "COMPLETED" : "Fail",
        "subscriptionPriceID": !isTrial ? subscription.items.data[0].plan.id : "TRIAL",
        "sessionId": sessionId,
        "subscriptionExpiryOn": !isTrial ? subscriptionExpiryOn : null,
        "isTrial": isTrial,
        "remaining": isTrial ? 50 : 0,
        "remarks": isTrial ? "Trial" : "",
        "userId": userId,
        "isAutorenewal": isAutorenewal,
        "subscriptionId": subscription == null ? "" : subscription.id
      };

      localStorage.setItem('subscription', JSON.stringify(subscriptionJson));

      let res = await httpPost(`updatePayment/`, subscriptionJson);
      if (res.status === 201) {
        const updatestatus = await res.json();
        if (updatestatus.status === "error") {
          alert(updatestatus.message);
          return;
        }

        await getUserSubscription();

        if (isTrial) {
          await initiateTrialService();
          setInitiateTrial(true);
        }
      }

      setTimeout(() => {
        navigate('/userProfile');
      }, 1000);


    } catch (error) {
      console.error('update subscription: ' + error);
      sendStripeSubscriptionFailureNotification(sessionId);
    }
  }


  const initiateTrialService = async () => {
    if (initiateTrial) return true;
    console.log(`initiateTrial : ${initiateTrial}`);
    let data: any; data = {
      "userEmail": username,
      "userId": userId
    }
    httpPost(`EmailProcessing/initiateTrialService/`, data);
  }

  const getUserSubscription = async () => {
    try {
      let res = null;
      let resJson: any; resJson = null;
      try {
        res = await httpGet(`getUserSubscription/${userId}`);
        resJson = await res.json();
      } catch (error) {
        setstripePlanId([]);
        setHasCompletedCheckoutBefore(false);
        setHasActivePaidSubscription(false);
        localStorage.removeItem("subscription");
        console.log(error);
        return;
      }

      if (resJson === null || resJson.currentPlan === null) {
        setStripeSession("");
        setstripePlanId([]);
        setHasCompletedCheckoutBefore(Boolean(resJson.history?.length > 0));
        setHasActivePaidSubscription(false);
        localStorage.removeItem("subscription");
        return;
      }

      localStorage.setItem('subscription', JSON.stringify(resJson.currentPlan));
      if ((new Date(resJson.currentPlan.subscriptionExpiryOn) >= new Date()) === true) {
        setStripeSession(resJson.currentPlan.sessionId === null ? "" : resJson.currentPlan.sessionId);
      }
      else if (resJson.currentPlan.isTrial === true && resJson.currentPlan.remaining > 0) {
        setStripeSession(resJson.currentPlan.sessionId === null ? "" : resJson.currentPlan.sessionId);
      }
      else {
        setStripeSession("");
      }

      if (resJson?.currentPlan || resJson?.history?.length > 0) {
        setHasActivePaidSubscription(Boolean(resJson.currentPlan?.isTrial === false));
        setHasCompletedCheckoutBefore(true);
      }

    } catch (error) {
      console.error('getUserSubscription Error: ' + error);
    }
  }

  const formatDate = (date: string) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  return (
    <div className={styles.userProfile} style={{ display: stage === ONBOARD_STAGES.LOADING ? 'none' : 'grid' }}>
      <OnboardingStepper />
      {(stage === ONBOARD_STAGES.ACTIVATE ||
        stage === ONBOARD_STAGES.SUBSCRIBE ||
        stage === ONBOARD_STAGES.COMPLETE)
        && <Membership setPaymentStatusChange={stripeSession} />
      }
      <UserPreferences
        showTemplates={stage === ONBOARD_STAGES.CONFIG_PREFERENCES}
      />
    </div>
  );
}

export default UserProfile;
