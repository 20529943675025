import React from 'react';
import {useTranslation} from "react-i18next";
import styles from '../style/custom.module.css'
import 'bootstrap/dist/css/bootstrap.css';

const MessagePopup = ({ isOpen = false as boolean, togglePopup = {} as any, errorMessage = '' as string,errorMessageHandler = '' as string }) => {
    // @ts-ignore TODO shouldn't have to do this, fix.
    const {t} = useTranslation();
    return (
        <div>
            {isOpen && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modal}>
                        <div className={styles.modalHeader}>
                            <h4>{errorMessageHandler}</h4> &nbsp;&nbsp;&nbsp;&nbsp;
                            <button className={styles.closeIcon} onClick={togglePopup}> X</button>
                        </div>
                        <div className={styles.modalContent}>
                            <p>{errorMessage}</p>
                        </div>
                        <div className={styles.modalFooter}>
                            <button onClick={togglePopup}>{t('common.close')}</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MessagePopup;
