import React, {createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import Cookies, {CookieAttributes} from "js-cookie";
import {useTranslation} from "react-i18next";
import {jwtDecode} from "jwt-decode";
import {httpGet, httpPost, setAuthToken, clearAuthToken} from "../helpers/http.helper";
import {setLanguage} from "../translations";
import i18next from "i18next";
import {setOnForbidden} from "../helpers/http.helper";

const cookieAttributes: CookieAttributes = process.env.NODE_ENV === 'development' ? {
    sameSite: 'Strict',
    secure: false,
} : {
    sameSite: 'Strict',
    secure: true,
}

type AuthContextType = {
    loggedIn: boolean | null,
    login: ({ username, password }: { username: string, password: string }) => Promise<Response>,
    logout: () => void,
    username: string | null,
    userId: string | null,
};

const AuthContext = createContext<AuthContextType>({
    loggedIn : null,
    login: () => Promise.reject(new Error('Login function not implemented.')),
    logout: () => undefined,
    username: null,
    userId: null
});

export const useAuthContext = (): AuthContextType => useContext(AuthContext);

export const AuthContextProvider = ({
    children
}: {
    children: ReactNode
}) => {
    // @ts-ignore TODO shouldn't have to do this, fix.
    const { t } = useTranslation();
    const [loggedIn, setLoggedIn] = useState<AuthContextType['loggedIn']>(null)

    const [username, userId] = useMemo(() => {
        const userId = Cookies.get('nm_u') || null;
        let username = null;
        const jwt = Cookies.get('nm');
        if (jwt) {
            const decodedJwt = jwtDecode<{ username: string }>(jwt);
            username = decodedJwt.username;
        }

        if (username && userId) {
            return [username, userId];
        }
        return [null, null];
        // loggedIn is an intentional dependency, we want to keep these up
        // to date with auth state.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn]);

    const logout = useCallback(() => {
        Cookies.remove('nm');
        Cookies.remove('nm_u');
        clearAuthToken();
        localStorage.clear();
        setLoggedIn(false);
    }, []);

    useEffect(() => {
        setOnForbidden(logout)
    }, [setOnForbidden, logout]);

    const login = useCallback(async ({ username, password }: {username: string, password: string}) => {
        const response = await httpPost('login', { username: username.toLowerCase(), password });

        if (!response.ok) {
            const errorData = await response.json();
            if (errorData.message === "Unauthorized") {
                throw new Error(t('auth.errors.incorrectUserOrPassword'));
            }
            else {
                throw new Error(errorData.message || t('auth.errors.failedToLogin'));
            }
        }

        const data = await response.json();

        Cookies.set('nm', data.access_token, cookieAttributes);
        Cookies.set('nm_u', data.userId, cookieAttributes);

        setAuthToken(data.access_token);

        setLoggedIn(true);

        if (!data.preferredLanguage) {
            // No language is currently persisted
            //
            // @ts-ignore no typing available for resolvedLanguage
            setLanguage(i18next.resolvedLanguage, data.userId);
        } else if (data.preferredLanguage !== i18next.resolvedLanguage) {
            // The user has manually changed the language from the default prior to
            // logging in AND the persisted language does not match the resolved
            // language
            //
            // @ts-ignore no typing available for resolvedLanguage
            setLanguage(i18next.resolvedLanguage, data.userId);
        } else {
            // Set the language to the user's preferred language.
            // null userId is passed because we do not want to persist - it's already
            // persisted
            setLanguage(data.preferredLanguage, null);
        }

        return response;
    }, [t]);

    useEffect(() => {
        const checkAuthentication = async () => {
            if (username === null) {
                logout();
            } else {
                try {
                    let res = await httpGet(`checkAuth/${username}`);
                    if (res.status === 200) {
                        setLoggedIn(true);
                    } else {
                        logout();
                    }
                } catch (error) {
                    console.error('Error checking authentication:', error);
                    logout();
                }
            }
        };
        checkAuthentication();
    }, [username, logout]);

    const value = useMemo(() => ({
        loggedIn,
        login,
        logout,
        username,
        userId,
    }), [loggedIn, login, logout, username, userId]);

    if (loggedIn === null) {
        return <div>{t('common.loading')}</div>;
    }

    return (
        <AuthContext.Provider
            value={value}
        >
            {children}
        </AuthContext.Provider>
    );
};
