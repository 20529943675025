import React from "react";
import "../App.css";
import {
  Col,
  Row,
  Button,
} from "antd";
import {useGoogleAuthContext} from "../contexts/GoogleAuthContext";
import styles from "../style/custom.module.css";
import {useTranslation} from "react-i18next";

const GoogleLogin = () => {
  // @ts-ignore TODO shouldn't have to do this, fix.
  const {t} = useTranslation();
  const {
    login, isInitialConnect
  } = useGoogleAuthContext();

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'center', padding: '16px 16px 32px', marginBottom: '16px', borderBottom: 'solid 1px lightgrey'}}>
        <Button
          className={[
            styles.buttonText,
            styles.signInButtonAlign,
            "btn btn-success btn-sm",
          ].join(" ")}
          onClick={() => login()}
        >
          {isInitialConnect ? t('profile.connectedAccounts.connectButton') : t('profile.connectedAccounts.reconnectButton')}
        </Button>
      </div>
    </>
  );
};

export default GoogleLogin;
