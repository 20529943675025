/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { httpGet } from '../../helpers/http.helper';
import styles from './../../style/custom.module.css'
import 'bootstrap/dist/css/bootstrap.css';

enum actionEnum {
  BLOCK = 'BLOCK',
  ACCEPT = 'ACCEPT',
  CREATECALENDAREVENT = 'CREATECALENDAREVENT',
  ACCEPTALL = 'ACCEPTALL',
  BLOCKALL = 'BLOCKALL',
}

const WebHook = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [getAction, setAction] = useState<string | null>('');

  const [getPageHeader, setPageHeader] = useState('Update Calender Events');
  const [getWaitingStatus, setWaitingStatus] = useState('');

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const action = params.get('a');
  const taskID = params.get('tid');
  const userID = params.get('uid');
  const status = params.get('s');
  const eventUrl = params.get('eid');
  const queryString = params.get('qs');

  React.useEffect(() => {
    handleAPI();
  }, [setAction]);

  const handleAPI = async () => {
    try {

      let response: any;
      setAction(action);

      if (action === actionEnum.CREATECALENDAREVENT) {

        setPageHeader('Create Task');
        setWaitingStatus('Creating Task. please wait...');
        response = await httpGet(`gmail/CreateCalendarEvents/${queryString}`);
      } else if (action === actionEnum.ACCEPTALL) {

        setPageHeader('Accept all always prioritize contents');
        setWaitingStatus('creating prioritize contents. please wait...');
        response = await httpGet(`EmailProcessing/updatekeyAndIrrelaventContents/${queryString}`);
      }
      else if (action === actionEnum.ACCEPT) {

        setPageHeader('Accept a always prioritize content');
        setWaitingStatus('creating a prioritize content. please wait...');
        response = await httpGet(`EmailProcessing/updatekeyAndIrrelaventContents/${queryString}`);
      }
      else if (action === actionEnum.BLOCKALL) {

        setPageHeader('Block all filter out contents');
        setWaitingStatus('creating a filter out. please wait...');
        response = await httpGet(`EmailProcessing/updatekeyAndIrrelaventContents/${queryString}`);
      }

      else if (action === actionEnum.BLOCK) {

        setPageHeader('Block a filter out content');
        setWaitingStatus('creating a filter out content. please wait...');
        response = await httpGet(`EmailProcessing/updatekeyAndIrrelaventContents/${queryString}`);
      }
      else {

        setPageHeader('Update Calender Events');
        setWaitingStatus('Updating calendar status. please wait...');
        response = await httpGet(`gmail/UpdateCalendarEvents/${taskID}/${userID}/${status}`);
      }

      if (response.ok) {

        if (action === actionEnum.CREATECALENDAREVENT) {

          let responseJson = await response.json();

          if (responseJson.status === 500) {
            setWaitingStatus('Invalid user. please try again...');
            return;
          }
          else if (responseJson.status === 401) {
            setWaitingStatus('Gmail token expired. Please login and connect your Gmail account.');
            return;
          }

          if (responseJson.data)
            window.location.href = responseJson.data.htmlLink;

          setShowPopup(true);
        }
        else if (action === actionEnum.ACCEPTALL || action === actionEnum.ACCEPT || action === actionEnum.BLOCKALL || action === actionEnum.BLOCK) {
          let responseJson = await response.json();
          if (responseJson.status === 500) {
            setWaitingStatus('Invalid user. please try again...');
            alert(`Something went wrong. Please try again.`)
            return;
          }
          else if (responseJson.status === 200) {
            setWaitingStatus('Updated successfully.');
            alert(`${responseJson.message}\nPage will be redirected to user profile after clicking OK.`)
          }
          setShowPopup(true);
          window.location.href = '/userProfile';
        }
        else {
          if (eventUrl)
            window.location.href = eventUrl;
          setShowPopup(true);
        }
      }
    } catch (error: any) {
      console.error('API request failed:', error.message);
    }

    return Promise.resolve();
  };


  return (
    <>
      <div className="card">
        <h3 style={{ textAlign: 'center', marginTop: '15px' }} >{getPageHeader} </h3>
        <div className="card">
          <div className={styles.webhookContainer}>

            {showPopup && (
              <div className={[styles.webhookinfo].join(" ")} style={{ textAlign: 'center' }}>
                <p>
                  {getAction === null && (
                    <span className='form-label'>Calendar status updates have been successfully processed for : <b> <span style={{ color: status?.toUpperCase() === 'ACCEPTED' ? 'green' : 'red' }}> {status?.toUpperCase()}</span></b> </span>
                  )}

                  {getAction === actionEnum.CREATECALENDAREVENT && (
                    <span className='form-label'>Task has been successfully processed</span>
                  )}

                </p>
                <p style={{ fontSize: '13px' }}><br />Please wait.. This page will be automatically redirected to calendar event.</p>
              </div>
            )}
            {!showPopup && <p style={{ textAlign: 'center' }}>
              <span className='form-label'><b> {getWaitingStatus} </b> </span>
            </p>}

          </div>
        </div>
      </div>

    </>
  );
};

export default WebHook;