import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col } from "antd";
import styles from "../style/custom.module.css";
import "bootstrap/dist/css/bootstrap.css";
import { httpGet } from "../helpers/http.helper";
import { Helmet } from 'react-helmet';
import {useAuthContext} from "../contexts/AuthContext";
import {useTranslation} from "react-i18next";

enum subscriptionTypeEnum {
  FREE = "FREE",
  PAID = "PAID",
}

const Membership = ({ setPaymentStatusChange = {} as any }) => {
  // @ts-ignore TODO shouldn't have to do this, fix.
  const {t} = useTranslation();
  const {username, userId} = useAuthContext();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [planName, setPlanName] = useState(t('profile.membership.plans.Free Demo'));
  const [isActive, setIsActive] = useState(false);
  const [isTrailExist, setIsTrailExist] = useState(false);
  const [allowUpgrade, setAllowUpgrade] = useState(false);
  const [planNameExpiry, setplanNameExpiry] = useState(t('profile.membership.noExpiryDescription'));
  const [planNameExpiryseverity, setplanNameExpiryseverity] = useState("L");
  const [subscriptionType, setSubscriptionType] = useState(
    subscriptionTypeEnum.PAID
  );

  useEffect(() => {
    const loadCurrentPlan = async () => {
      if (setPaymentStatusChange.length > 0) {
        await setPaymentStatus();
      }
      setHasLoaded(true);
    };
    loadCurrentPlan();
  }, [
    setPaymentStatusChange,
    setplanNameExpiryseverity,
    setSubscriptionType,
    isTrailExist,
  ]);

  const loadSubscriptionPrice = async (priceIndex: number) => {
    try {
      let res = await httpGet(`getSubscriptionPrice/${username}`);
      let proceList: any = await res.json();

      const index = proceList.findIndex(
        (obj: { id: number }) => obj.id === priceIndex
      );

      localStorage.setItem(
        "subscriptionPlan",
        JSON.stringify(proceList[index])
      );
    } catch (error) {
      console.error("OAuth2 Error: " + error);
    }
  };

  const setPaymentStatus = async () => {

    await getUserSubscription();

    let subscription: any = localStorage.getItem("subscription");
    const _subscription = JSON.parse(subscription);

    if (_subscription.subscriptionPriceID === undefined) return;

    await loadSubscriptionPrice(_subscription.subscriptionPriceID);

    let _currentDate5 = new Date();
    _currentDate5.setDate(_currentDate5.getDate() + 5);
    let _currentDate10 = new Date();
    _currentDate10.setDate(_currentDate10.getDate() + 10);

    const subscriptionPlan: any = localStorage.getItem("subscriptionPlan");
    const _subscriptionPlan = JSON.parse(subscriptionPlan);

    if (
      _subscriptionPlan !== undefined &&
      _subscriptionPlan.description !== undefined
    ) { // @ts-ignore
        setPlanName(t(`profile.membership.plans.${_subscriptionPlan.description}`));
    }

    let date = new Date(_subscription.subscriptionExpiryOn);
    date.setDate(date.getDate() + 1);

    setplanNameExpiry(formatDate(date));

    if ((new Date(_subscription.subscriptionExpiryOn) >= new Date()) === true) {
      setIsActive(true);
    }
    else if (_subscription.isTrial === true) {
      setIsActive(false);
    }
    else {
      setIsActive(false);
    }

    if (new Date(_subscription.subscriptionExpiryOn) > _currentDate10) {
      setplanNameExpiryseverity("N");
    } else if (new Date(_subscription.subscriptionExpiryOn) <= _currentDate10) {
      setplanNameExpiryseverity("M");
    } else if (new Date(_subscription.subscriptionExpiryOn) <= _currentDate5) {
      setplanNameExpiryseverity("H");
    }
    if (parseFloat(_subscriptionPlan.price) === 0) {
      setSubscriptionType(subscriptionTypeEnum.FREE);
    }
  };

  const formatDate = (date: any) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const getUserSubscription = async () => {
    try {
      let res = null;
      let resJson: any;
      resJson = null;
      try {
        res = await httpGet(`getUserSubscription/${userId}`);
        resJson = await res.json();
      } catch (error) {
        localStorage.removeItem("subscription");
        return;
      }

      if (resJson === null || resJson.currentPlan === null) {
        localStorage.removeItem("subscription");
        return;
      }
      setIsTrailExist(resJson.isTrailExist === true ? true : false);
      if (resJson.currentPlan.isTrial) {
        setAllowUpgrade(true);
      }

      localStorage.setItem("subscription", JSON.stringify(resJson.currentPlan));
    } catch (error) {
      console.error("getUserSubscription Error: " + error);
    }
  };

  return hasLoaded ? (
    <>
      <Helmet>
        {!isActive && (
          <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
        )}
      </Helmet>

      <div className="card">
        <div className={[styles.CardTitle, "card-header"].join(" ")}>
          {t('profile.membership.title')}
        </div>

        <div className="card-body">
          {/* allowUpgrade */}

          <Col style={{ display: isActive === true ? "none" : "block" }}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                {/*@ts-ignore*/}
                <stripe-pricing-table pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE}
                  publishable-key={process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY}>
                  {/*@ts-ignore*/}
                </stripe-pricing-table>
              </Col>
            </Row>
          </Col>


          <Col>
            <Row gutter={[16, 16]}>

              <Col span={8}>
                <span className={[styles.lables, "form-label"].join(" ")}>
                  {t('profile.membership.currentPlanLabel')}:
                  <span style={{ fontWeight: isActive ? "bold" : "" }}>
                    {` ${planName}`}
                  </span>
                </span>
              </Col>
              <Col span={5}>
                <span className={[styles.lables, "form-label"].join(" ")}>
                  {t('profile.membership.currentPlanStatusLabel')}:{` `}
                  <span
                    style={{
                      color: isActive ? "green" : (!isActive && allowUpgrade) ? "green" : "red",
                      fontWeight: isActive ? "bold" : "",
                    }}
                  >

                    {isActive ? "Active" : (!isActive && allowUpgrade) ? t('common.active') : t('common.inactive')}

                  </span>
                </span>
              </Col>
              <Col span={9}>
                <span
                  style={{ display: allowUpgrade === true ? "none" : "block" }}
                  className={[styles.lables, "form-label"].join(" ")}
                >
                  {t('profile.membership.nextRenewalDateLabel')}:{" "}
                  <span
                    style={{
                      color:
                        planNameExpiryseverity === "H"
                          ? "red"
                          : planNameExpiryseverity === "M"
                            ? "blue"
                            : planNameExpiryseverity === "N"
                              ? "green"
                              : "",
                      fontWeight: isActive ? "bold" : "",
                    }}
                  >
                    {" "}
                    {subscriptionType === subscriptionTypeEnum.FREE
                      ? t('profile.membership.trialExpiryDescription')
                      : planNameExpiry === null
                        ? ""
                        : planNameExpiry.substring(0, 10)}{" "}
                  </span>{" "}
                </span>
              </Col>
            </Row>
            <br />
            <Row gutter={[16, 16]}>
              <Col span={8}>
                {isActive === true && (
                  <a href="https://billing.stripe.com/p/login/9AQ9DM9Cp9PMg9y7ss" target="_blank">
                    <Button
                      className={[
                        styles.buttonText,
                        "btn btn-success btn-sm",
                      ].join(" ")}
                    >
                      {t('profile.membership.manageMembershipButton')}
                    </Button></a>
                )}
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    </>
  ) : null;
};

export default Membership;
