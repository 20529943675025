import React, { useState } from 'react';
import { Form, Input, Button, Divider, notification } from 'antd';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { httpPost } from '../../helpers/http.helper';
import styles from './../../style/custom.module.css'
import 'bootstrap/dist/css/bootstrap.css';
import useStrongPassword, { PasswordStrengthIndicator } from "../../hooks/useStrongPassword";

enum actionEnum {
    request = "r",
    forget = "f",
}

const ForgotPassword = () => {
    // @ts-ignore TODO shouldn't have to do this, fix.
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const action = params.get('a');
    const referenceId = params.get('rid');
    const intialEmail = params.get('e') ?? '';

    const [getAction, setAction] = useState<string>('');
    const [username, setUsername] = useState(intialEmail);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [fieldErrors, setFieldErrors] = useState<any>(null);

    React.useEffect(() => {
        handleRequest();
    }, []);


    const handleOnPasswordChange = (e: any) => {
        setFieldErrors({});
        setPassword(e.target.value);
    }

    const handleOnConfirmPasswordChange = (e: any) => {
        setFieldErrors({});
        setConfirmPassword(e.target.value);
    }

    const handleRequest = async () => {
        try {
            if (action === actionEnum.request) {
                setAction(actionEnum.request);
            }
            else if (action === actionEnum.forget) {
                setAction(actionEnum.forget);
            }
        } catch (error: any) {
            console.error('API request failed:', error.message);
        }

        return Promise.resolve();
    };

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    const validateForgetForm = () => {
        const newErrors: any = {};
        if (!username) newErrors.username = t('auth.errors.requiredEmail');
        else if (!validateEmail(username)) newErrors.username = t('auth.errors.invalidEmail');
        setFieldErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validateResetForm = () => {
        const newErrors: any = {};
        if (!confirmPassword) newErrors.confirmPassword = t('auth.errors.requiredConfirmPassword');
        if (!password) newErrors.password = t('auth.errors.requiredPassword');
        else if (confirmPassword !== password) newErrors.confirmPassword = t('auth.errors.passwordDoesNotMatch');
        setFieldErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleRequestPassword = async () => {
        let data: any;
        if (!validateForgetForm()) return;
        const response = await httpPost('requestNewPassword', { username: username.toLowerCase() });

        if (response.ok)
            data = await response.json();

        if (!response.ok) {
            notification.error({
                message: t('auth.errors.updatePasswordFailed'),
                placement: "bottomRight"
            })
            return;
        }
        else if (data.status === "error") {
                notification.error({
                    message: data.message,
                    placement: "bottomRight"
                })
                return;
            }

        notification.success({
            message: t('auth.success.resetPasswordRequested'),
            placement: "bottomRight"
        })
        setTimeout(() => {
            navigate('/login');
        }, 1 * 5000);
    };


    const handleUpdatePassword = async () => {
        if (!validateResetForm()) return;
        if (passwordIssues.length > 0) return;
        let data: any;

        const response = await httpPost('resetPassword', { password: btoa(password), referenceid: referenceId });
        if (response.ok)
            data = await response.json();

        if (!response.ok) {
            notification.error({
                message: t('auth.errors.updatePasswordFailed'),
                placement: "bottomRight"
            })
            return;
        }
        else  if (data.status === "error") {
                notification.error({
                    message: data.message,
                    placement: "bottomRight"
                })
                return;
            }
     

        notification.success({
            message: t('auth.success.resetPasswordCompleted'),
            placement: "bottomRight"
        })
        setTimeout(() => {
            navigate('/login');
        }, 1 * 5000);
    };

    const passwordIssues = useStrongPassword(password, confirmPassword);

    return (
        <>
            <div className={styles.login}>
                <Form className={[styles.authForm, getAction === actionEnum.request ? styles.requestResetPasswordForm : styles.resetPasswordForm].join(' ')} colon={false}>
                    {getAction === actionEnum.request && <>
                        <label>
                            <span>{t('auth.emailLabel')}</span>
                            <div>
                                <Input type="email" value={username} onChange={(e) => setUsername(e.target.value)} />
                                {fieldErrors?.username && <div className={styles.fieldError}>{fieldErrors.username}</div>}
                            </div>
                        </label>
                        <div className={styles.authFormRow}>
                            <Button onClick={handleRequestPassword}
                                className={[styles.buttonText, "btn btn-primary btn-sm"].join(' ')}
                                htmlType="submit">
                                {t('auth.resetPassword')}
                            </Button>
                        </div>
                    </>}
                    {getAction === actionEnum.forget && <>
                        <label>
                            <span>{t('auth.newPasswordLabel')}</span>
                            <div>
                                <Input.Password value={password}
                                    onChange={handleOnPasswordChange} />
                                {fieldErrors?.password && <div className={styles.fieldError}>{fieldErrors.password}</div>}
                            </div>
                        </label>
                        <label>
                            <span>{t('auth.confirmPasswordLabel')}</span>
                            <div>
                                <Input type="password" value={confirmPassword}
                                    onChange={handleOnConfirmPasswordChange} />
                                {fieldErrors?.confirmPassword &&
                                    <div className={styles.fieldError}>{fieldErrors.confirmPassword}</div>}
                            </div>
                        </label>
                        <Divider />
                        <PasswordStrengthIndicator passwordIssues={passwordIssues} />
                        <br />
                        <div className={styles.authFormRow}>
                            <Button
                                onClick={handleUpdatePassword}
                                className={[styles.buttonText, "btn btn-primary btn-sm"].join(' ')}
                                htmlType="submit"
                            >
                                {t('auth.updatePassword')}
                            </Button>
                        </div>
                    </>}
                    <Divider />
                    <center>
                        <Link to="/login">{t('common.cancel')}</Link>
                    </center>
                </Form>
            </div>
        </>
    );
};

export default ForgotPassword;