import React, {createContext, ReactNode, useContext, useMemo, useState} from 'react';
import {useGoogleAuthContext} from "./GoogleAuthContext";
import {useAuthContext} from "./AuthContext";

export const ONBOARD_STAGES = {
    LOADING: 'LOADING',
    SIGNUP: 'SIGNUP',
    GOOGLE_AUTH: 'GOOGLE_AUTH',
    CONFIG_PREFERENCES: 'CONFIG_PREFERENCES',
    ACTIVATE: 'ACTIVATE',
    SUBSCRIBE: 'SUBSCRIBE',
    COMPLETE: 'COMPLETE',
    GOOGLE_REAUTH: 'GOOGLE_REAUTH',
} as const;


type OnboardingContextType = {
    stage: keyof typeof ONBOARD_STAGES
    setHasConfiguredPreferences: React.Dispatch<boolean>,
    setHasActivePaidSubscription: React.Dispatch<boolean>,
    setHasCompletedCheckoutBefore: React.Dispatch<boolean>,
};

const OnboardingContext = createContext<OnboardingContextType>({} as OnboardingContextType);

export const useOnboardingContext = (): OnboardingContextType => useContext(OnboardingContext);

export const OnboardingContextProvider = ({
    children
}: {
    children: ReactNode
}) => {
    const { loggedIn } = useAuthContext();
    const [hasConfiguredPreferences, setHasConfiguredPreferences] = useState<boolean | null>(null);
    const [hasCompletedCheckoutBefore, setHasCompletedCheckoutBefore] = useState<boolean | null>(null)
    const [hasActivePaidSubscription, setHasActivePaidSubscription] = useState<boolean | null>(null)

    const {googleUser, isInitialConnect} = useGoogleAuthContext();
    const googleUserLoaded = googleUser !== undefined;

    let stage: keyof typeof ONBOARD_STAGES = ONBOARD_STAGES.LOADING;
    if (loggedIn === false) {
        stage = ONBOARD_STAGES.SIGNUP;
    } else if (
        !googleUserLoaded ||
        loggedIn === null ||
        hasConfiguredPreferences === null ||
        hasActivePaidSubscription === null ||
        hasCompletedCheckoutBefore === null
    ) {
        stage = ONBOARD_STAGES.LOADING;
    } else {
        if (!hasConfiguredPreferences) {
            stage = ONBOARD_STAGES.CONFIG_PREFERENCES;
        }
        else if (!googleUser) {
            if (isInitialConnect) {
                stage = ONBOARD_STAGES.GOOGLE_AUTH;
            } else {
                stage = ONBOARD_STAGES.GOOGLE_REAUTH;
            }
        } else {
            if (!hasCompletedCheckoutBefore) {
                stage = ONBOARD_STAGES.ACTIVATE;
            } else if (!hasActivePaidSubscription) {
                stage = ONBOARD_STAGES.SUBSCRIBE;
            } else {
                stage = ONBOARD_STAGES.COMPLETE;
            }
        }
    }

    const value = useMemo(() => ({
        stage,
        setHasConfiguredPreferences,
        setHasActivePaidSubscription,
        setHasCompletedCheckoutBefore,
    }), [stage]);

    return (
        <OnboardingContext.Provider
            value={value}
        >
            {children}
        </OnboardingContext.Provider>
    );
};
